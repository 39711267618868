import * as React from 'react';

import { Button, Checkbox, FormControlLabel, TextField, Slider, Stack } from '@mui/material';
import { VolumeDown, VolumeUp, ArrowBack } from '@mui/icons-material';
import { AppBar, NavLink } from 'components';
import { useNavigate } from "react-router-dom";

import "./Clock.css"


const SEC_TO_MS = 1000;
const MIN_TO_SEC = 60;

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * MIN_TO_SEC * SEC_TO_MS)
}

function Clock() {
  const [audio] = React.useState(new Audio("/alarm.mp3"));
  const [time, setTime] = React.useState(new Date());
  let minutes = React.useRef(10);
  let shouldAutoRestart = React.useRef(false);
  let shouldPlayAlarm= React.useRef(false);
  let active = React.useRef(true);
  let target = React.useRef(addMinutes(new Date(), 10));
  const navigate = useNavigate();
  
  React.useEffect(() => {
    const interval = setInterval(() => {
      let now = new Date();
      setTime(now);

      if (active.current && now > target.current) {
        active.current = false;
        if (shouldPlayAlarm.current) audio.play();
        if (shouldAutoRestart.current) startAlarm();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const startAlarm = () => {
    target.current = addMinutes(new Date(), minutes.current);
    active.current = true;
  }

  const val = () => {
    let now=  new Date();
    if (now > target.current)
      return 0;
    
    let diff = target.current.getTime() - now.getTime();
    let x = diff / MIN_TO_SEC / SEC_TO_MS / 60;
    
    return Math.min(1, Math.max(0, x));
  }
  
  const backgroundImage = () => {
    let progress = 1 - val();
    if (progress > 0.5) {
      return `linear-gradient(calc(-90deg + ${progress * 360}deg), white 50%, transparent 50%),
              linear-gradient(-90deg, red 50%, transparent 50%)`
    }
    
    return `linear-gradient(calc(90deg + ${progress * 360}deg), transparent 50%, red 50%),
              linear-gradient(90deg, red 50%, transparent 50%)`
  }
  
  const transform = () => {
    let progress = 1 - val();
    if (progress > 0.5) {
      return `scaleX(-1) scaleY(-1)`;
    }
    
    return `scaleX(1)`;
  }
  
  const onChangeShouldPlayAlarm = (e) => {
    shouldPlayAlarm.current = e.target.checked;
  }

  const onChangeShouldAutoRestart = (e) => {
    shouldAutoRestart.current = e.target.checked;
  }

  return (
    <div className="clock-container">
        <AppBar />
        <Stack className="clock-controls" spacing={1} direction="column">
          <ArrowBack onClick={() => navigate(-1)} />
          <a class="clock-logo" href="/">
            <p>agilify<font color="orangered">.</font>it</p>
          </a>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <VolumeDown />
            <Slider
              size="small"
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
              defaultValue={100}
              aria-label="Volume"
              onChange={(e) => audio.volume = e.target.value / 100.0} />
            <VolumeUp />
          </Stack>
          <FormControlLabel
            control={<Checkbox onChange={onChangeShouldPlayAlarm} />}
            label="Play Alarm Sound"
          />
          <FormControlLabel
            control={<Checkbox onChange={onChangeShouldAutoRestart} />}
            label="Auto Restart"
          />
          <TextField
            id="inputMinutes"
            label="Minutes"
            type="number"
            defaultValue={10}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              let mins = parseInt(e.target.value, 10);
              if (Number.isNaN(mins))
                return;
              
              minutes.current = mins;
            }}
          />
          <Button
            variant="outlined"
            onClick={startAlarm}
          ><b>START</b></Button>
        </Stack>
      <div className="clock">
        <img src="/clock.png" style={{width: "100%", height: "100%"}}  alt={"image of a clock"}/>
        <div
          className="ten"
          style={{backgroundImage: backgroundImage(), transform: transform()}}
        />
      </div>
    </div>
  ); 
} 

export default Clock;