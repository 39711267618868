import * as React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import { NavLink } from 'components';

import "./Sidebar.css";

type SidebarEntryProps = {
  label : string;
  depth : number;
  path  : string;
  sx: React.CSSProperties;
}

export function SidebarEntry(props : SidebarEntryProps) {
  const isSelected = window.location.pathname === props.path;

  return (
    <ListItemButton
      className={props.depth === 0 ? 'sidebar-entry-topic' : 'sidebar-entry-article'}
      component={NavLink}
      selected={isSelected}
      to={props.path}
      onClick={props.onClick}
      sx={[{ pl: `${16 + 16 * props.depth}px`}, props.sx]}>
      <ListItemText primary={props.label} />
    </ListItemButton>
  )
}