

export const getArticles = () => {
    return ARTICLES;
}

const ARTICLES =  [
    {
        path: "/article/break-free-from-scrum",
        title: "Break Free from Scrum",
        showDisclaimer: true,
        titleImage: '/images/standing_meeting.webp',

        homeOrder: -90,
        showOnHome: true,
        category: "Opinion Piece",
        preview: "Break free from the shackles of Scrum and embark on a journey to find your ideal process. Learn how to challenge Scrum's rigidity, abolish unnecessary meetings, and conduct experiments to shape a workflow that suits your team's unique needs.",
        updatedAt: Date.parse('04.29.2024 00:00:00 UTC+1'),
    },
    {
        path: "/article/why-sprints-dont-work",
        title: "Why Sprints Don't Work",
        showDisclaimer: true,
        titleImage: '/images/kanban-board.jpg',

        homeOrder: -100,
        showOnHome: true,
        category: "Opinion Piece",
        preview: "The sprint is over, half the issues were not completed, Devs and Management are frustrated. Here is why you should finally stop using Sprints.",
        updatedAt: Date.parse('04.12.2024 00:00:00 UTC+1'),
    },
    {
        path: "/article/stop-solving-issues-that-dont-matter",
        title: "Stop Solving Issues That Don't Matter",
        showDisclaimer: true,
        titleImage: '/images/frontend.webp',

        homeOrder: -80,
        showOnHome: true,
        category: "Opinion Piece",
        preview: "Something went wrong, and before you know it, another weekly meeting is scheduled to prevent that from happening again. But is that really worth it?",
        updatedAt: Date.parse('04.10.2024 00:00:00 UTC+1'),
    },
    {
        path: "/article/what-i-value",
        title: "What I Value",
        showDisclaimer: true,
        titleImage: '/images/meeting.jpg',

        homeOrder: -70,
        showOnHome: false,
        category: "Opinion Piece",
        preview: "Is your team ready to break free from the shackles of Scrum? Where to start, and more.",
        updatedAt: Date.parse('04.29.2024 00:00:00 UTC+1'),
    },
    {
        path: "/article/lean-coffee",
        title: "Lean Coffee",
        showDisclaimer: false,
        showOnHome: false,
    },
    {
        path: "/article/post-mortem",
        title: "Post Mortem",
        showDisclaimer: false,
        showOnHome: false,
    },
    {
        path: "/article/five-whys",
        title: "Five Why's",
        showDisclaimer: false,
        showOnHome: false,
    },
    {
        path: "/article/cognitive-load",
        title: "Cognitive Load",
        showDisclaimer: false,
        titleImage: '/images/cognitive-load.webp',
        showOnHome: false,
    },
    {
        path: "/article/where-i-recommend-to-start-from",
        title: "Where I Recommend To Start From",
        showDisclaimer: true,
        titleImage: '/images/frontend.webp',
        showOnHome: false,
    },
    {
        path: "/article/moderation-101",
        title: "How To Be An Effective Moderator",
        showDisclaimer: false,
        titleImage: '/images/meeting.jpg',
        showOnHome: false,
    }
]