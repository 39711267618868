

function Disclaimer() {     
  return (         
    <div className='disclaimer'>
      All articles are written based on my personal experience. I mostly work in development teams of up to 15 people in companies ranging from 20 to 300 people.
    </div>
  ); 
} 

export default Disclaimer;