import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import '@fontsource-variable/inconsolata';

const colors = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        bg_level0: { main: '#ffffff' },
        bg_level1: { main: '#ffffff' }
      }
    },
    dark: {
      palette: {
        bg_level0: { main: '#101418' },
        bg_level1: { main: '#101418' }
      }
    }
  },
})

export const appTheme = extendTheme({
  colors
});