import React from 'react'; 
import { Link } from 'react-router-dom';

export default function AgileToolkit() {     

  return (         
          <div className="content">
            <img className='content-title-image' src="/test.png" alt="cartoon of small people standing around a much larger screen"/>
            {/* <img src="/test.png" alt="image" style={{width: "70%", alignSelf: "center"}}/>     */}
            <h1>Don't Use Scrum</h1>

            When people ask where to start they often get pointed to <Link to="/articles/agile-scrum">Scrum</Link>. And while
            that is a good starting point for inexperienced teams, it also leads a lot of people to believe that <i>Agile Software Development </i> 
            means doing <Link to="/articles/agile-scrum">Scrum</Link>. 
            
            <h2><center>It's not.</center></h2>

            Being <i>Agile</i> means to me being able to adjust to an ever changing environment. Enthusiasts have created a miriad of different practices
            over the years that each target a specific problem. None of them claim to fix everything. Therefore you and your team should only pick the pieces 
            that fit your current needs.
            <br /><br />
            View it as the <i>Agile Toolkit</i> where you take out practices when you need them, and put them back when you don't.
            {/* <br /><br />
            STORY-TIME: I once worked as a game-dev in what was the fastest changing environment I've ever worked in. We would start
            with a 3 month prototyping phase with one dev, one artist and one game-designer per project.
            We only did daily <Link to="/articles/standup">Stand Ups</Link> and a monthly team <Link to="/articles/retro">Retrospective</Link>. Given only a limited
            amount of people were involved in each project at this stage, information would spread through direct conversations.
            <br /><br />
            The moment we commited on a game idea we would put multiple devs, artists, game-designers and QAs on it. This added complexity of coordination led us to adding
            a weekly <Link to="/articles/planning">Planning</Link>, a weekly <Link to="/articles/groomings">Grooming</Link> and a weekly 
            <Link to="/articles/agile-scrum">Build Review</Link>. We also ditched the monthly team-focused <Link to="/articles/retro">Retrospective</Link> for a bi-weekly 
            project-focused <Link to="/articles/retro">Retrospective</Link>.
            <br /><br />
            Once the game reached maintenance mode we would restart the prototyping phase, shrinking down the processes to daily <Link to="/articles/standup">Stand Ups</Link>
            and the monthly team <Link to="/articles/retro">Retrospective</Link>. And so the cycle would continue. */}
          </div> 
  ); 
} 