import * as React from 'react';
import { AppBar as MUIAppBar, Drawer, Typography, IconButton, Toolbar, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, NavigationList, renderNavList } from '..';

import './AppBar.css';

export const AppBar: React.FunctionComponent<void> = () => {
  const [open, setOpen] = React.useState(false);
  
  const toggleDrawer = () => {
    setOpen(!open);
  }
  
  return (         
    <Box className="app-bar" sx={{ flexGrow: 1 }}>
      <MUIAppBar position="fixed" >
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography component="div" sx={{ flexGrow: 1, selfAlign: "center", display: "flex", justifyContent: "center" }}>
            <NavLink to="/" className='app-bar-logo logo-text'>agilify<font className='logo-text-accent'>.</font>it</NavLink>
          </Typography>
          <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }} onClick={() => {}}>
            <MenuIcon sx={{ opacity: 0.0 }} />
          </IconButton>
        </Toolbar>
      </MUIAppBar>
      <Toolbar />
      <Drawer
        open={open}
        variant="temporary"
        sx={{
          '& .MuiDrawer-paper': {
            marginTop: "56px",
          },
          '& .MuiModal-backdrop': {
            marginTop: "56px",
          },
          marginTop: "56px",
        }}>
        <Box sx={{ width: "100vw"}} role="presentation">
          {renderNavList(NavigationList, toggleDrawer)}
        </Box>
      </Drawer>
    </Box>
  ); 
} 