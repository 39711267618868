import React from 'react';

export default function AgileStandup() {     

  return (         
          <div className="content">
          

            <img src="/test.png" alt="cartoon of small people standing around a much larger screen" style={{width: "70%", alignSelf: "center"}}/>
            <h1>Agile Toolkit</h1>

            <br /><br />
            For the longest time I was adamant: <i>Devs should not have to give estimations. It's
            something management wants, so it's their problem.</i>
            <br /><br />
            In this article we'll disect:
            <ul>
              <li>What does management keep trying to push estimations on us?</li>
              <li>The dangers of introducing estimations</li>
            </ul>

            <h1>Dangers and pitfalls</h1>
          </div>
  ); 
} 