import * as React from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom';

type NavLinkProps = {
    href: string
    className: string
}

export const NavLink: React.FunctionComponent<NavLinkProps> = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
    return (
        <NavLinkBase ref={ref} to={props.href}
            {...props}
            className={({ isActive }) => (isActive ? props.className + ' Mui-selected' : props.className)}
        />
    )
});
