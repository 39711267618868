export enum NavListType {
  Category = "category",
  Page = "page",
  Divider = "divider",
};

export type NavigationListEntry = {
  type  : NavListType;
  label : string;
  path  : string;
  dev?  : boolean;
};

export const NavigationList : NavigationListEntry[] = [
  {
    type:  NavListType.Category,
    label: "Opinion Pieces",
    path:  null,
  },
  {
    type:  NavListType.Page,
    label: "Break Free From Scrum",
    path:  '/article/break-free-from-scrum',
  },
  {
    type:  NavListType.Page,
    label: "What I Value",
    path:  '/article/what-i-value',
  },
  {
    type:  NavListType.Page,
    label: "Stop Solving Issues That Don't Matter",
    path:  '/article/stop-solving-issues-that-dont-matter',
  },
  {
    type:  NavListType.Page,
    label: "Why Sprints Don't Work",
    path:  '/article/why-sprints-dont-work',
  },
  {
    type:  NavListType.Page,
    label: "Where I Recommend To Start From",
    path:  '/article/where-i-recommend-to-start-from',
    dev: true,
  },
  {
    type:  NavListType.Divider,
    label: "",
    path:  null,
  },
  {
    type:  NavListType.Category,
    label: "Coding",
    path:  null,
  },
  {
    type:  NavListType.Page,
    label: "Cognitive Load",
    path:  '/article/cognitive-load',
  },
  {
    type:  NavListType.Divider,
    label: "",
    path:  null,
  },
  {
    type:  NavListType.Category,
    label: "Agile Toolkit",
    path:  null,
  },
  {
    type:  NavListType.Page,
    label: "Lean Coffee",
    path:  '/article/lean-coffee',
  },
  {
    type:  NavListType.Page,
    label: "Post Mortem",
    path:  '/article/post-mortem',
    dev: true,
  },
  {
    type:  NavListType.Page,
    label: "Five Why's",
    path:  '/article/five-whys',
  },
  {
    type:  NavListType.Divider,
    label: "",
    path:  null,
  },
  {
    type:  NavListType.Category,
    label: "Other",
    path:  null,
  },
  {
    type:  NavListType.Page,
    label: "How To Be An Effective Moderator",
    path:  '/article/moderation-101',
  },
  {
    type:  NavListType.Page,
    label: "Resources",
    path:  '/resources',
  },
  {
    type:  NavListType.Page,
    label: "Timebox Clock",
    path:  '/clock',
  },
  {
    type:  NavListType.Page,
    label: "About Me",
    path:  '/about-me',
  }
];