import React from "react"; 
import {Outlet} from "react-router-dom";
import { Sidebar, Footer, AppBar } from "components";
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { appTheme } from "../themes/theme";

const Layout = () => { 
  return (
    <CssVarsProvider theme={appTheme} defaultMode="dark"> 
    <div className="page-container"> 
      <AppBar />
      <div className="home-container">
        <Sidebar />

        <div className="page">
          <Outlet />    
          <Footer />    
        </div>
      </div>
    </div>
    </CssVarsProvider>   
  ); 
}; 

export default Layout;