import * as React from 'react';
import "./Sidebar.css";
import { SidebarEntry } from ".";
import { NavigationListEntry, NavListType } from ".."

export function renderNavList(navigation : NavigationListEntry[], onClick? : () => void) {

    const renderEntry = (entry: NavigationListEntry) => {
        const isProd = process.env.NODE_ENV !== "development";
        if (entry.dev) {
            if (isProd) return <></>;
            return <SidebarEntry label={entry.label} depth={1} path={entry.path} sx={{color: "red"}} onClick={onClick}/>
        }

        if (entry.type === NavListType.Category) {
            return <div className='sidebar-entry-category'>{entry.label}</div>;
        }
    
        if (entry.type === NavListType.Page) {
            return <SidebarEntry label={entry.label} depth={1} path={entry.path} onClick={onClick}/>
        }
    
        if (entry.type === NavListType.Divider) {
            return <div className='sidebar-entry-spacer' />
        }

        return <></>;
    }

    return <nav>{navigation.map((entry) => renderEntry(entry))}</nav>
}