import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

function Footer() {     
    return (
    <footer className="footer">
      <div className="content">
        <div className="footer-wotc-note">
          <a href="https://www.iubenda.com/privacy-policy/31329380" className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
          &nbsp;|&nbsp;
          <Link to="/legal">Imprint and Disclaimers</Link>
          &nbsp;|&nbsp;
          <a href="https://www.iubenda.com/privacy-policy/31329380/cookie-policy" className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a></div>
        <br />
        <div className="footer-wotc-note">
        Content is based on personal experience with altered details for privacy. Resemblances to real persons are coincidental. The owner is not liable for content interpretation. Stories represent personal views, not factual identifications.
        </div>
      </div>
    </footer>);
}

export default Footer;