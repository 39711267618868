import React from 'react'
import ReactMarkdown from 'react-markdown'
import Skeleton from '@mui/material/Skeleton';
import { NavLink } from 'components';
import rehypeRaw from 'rehype-raw';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'

//import {funky} from 'react-syntax-highlighter/dist/esm/styles/prism'
import funky from './codestyle2.js'

import "./Markdown.css";

type MarkdownProps = {
  children?: string
  path?: string;
}

export default function Markdown(props : MarkdownProps) {    
  const [text, setText] = React.useState(props.children);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!props.path) {
      setIsLoading(false);
      return;
    }

    fetch(props.path).then((response) => response.text()).then((text) => {
      setIsLoading(false);
      setText(text);
    })
  }, [props.path, props.children]);

  const componentOverrides = {
    img(props) {
      const {node, ...rest} = props
      return <div style={{display: "flex", justifyContent: "space-around"}}>
          <img className='markdown-inline-img' src={props.src} {...rest} />
        </div>
    },
    banner(props) {
      const {node, ...rest} = props
      return <div style={{display: "flex", justifyContent: "space-around"}}>
        <img className='markdown-inline-banner' src={props.src} {...rest} />
      </div>
    },
    a(props) {
      const {node, ...rest} = props
      return <NavLink style={{}} {...rest} />
    },
    disclaimer(props) {
      return <div className='disclaimer'>{props.children}</div>
    },
    cite(props) {
      var id = props.children[0];

      return (
        <div
          className='citation'
          onClick={() => {
            const section = document.querySelector( `#citesource-${id}` );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
          }}>
          [{id}]
        </div>
      )
    },
    note(props) {
      return (
        <div className='note'>
          <div className='note-label'>NOTE</div>
          <div className='note-content'>
            {props.children}
          </div>
        </div>
        )
    },
    activity(props) {
      return (
        <div className='activity'>
          <div className='activity-row'>
            <div className='activity-content' style={{flexBasis: "33%", flexGrow: 0, flexShrink: 0}}>
              <div className='activity-label'>DURATION</div>
              {props.duration}
            </div>
            <div className='activity-content'>
              <div className='activity-label'>REQUIREMENTS</div>
              {props.children}
            </div>
          </div>
          <div className='activity-row'>
            <div className='activity-content' style={{flexGrow: 1, flexShrink: 1}}>
              <div className='activity-label'>WHEN TO USE</div>
              {props.when}
            </div>
          </div>
        </div>
        )
    },
    citesource(props) {
      return <div className={"citesource"} id={`citesource-${props.num}`}>[{props.num}] {props.children}</div>
    },
    code(props) {
      const {children, className, node, ...rest} = props
      const match = /language-(\w+)/.exec(className || '')
      return match ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={match[1]}
          style={funky}
        />
        ) : (
          <SyntaxHighlighter
            {...rest}
            PreTag="span"
            children={String(children).replace(/\n$/, '')}
            language={"js"}
            style={funky}
          />
        )
    }
  }

  return (
    isLoading ? <Skeleton variant="text" width={"100%"} height={800} sx={{ backgroundColor: 'transparent' }} /> :
        <ReactMarkdown className='markdown' components={componentOverrides} rehypePlugins={[rehypeRaw]}>
          {text}
        </ReactMarkdown>
      );
}