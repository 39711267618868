import * as React from 'react';
import { Link } from 'react-router-dom';
import { NavigationList } from ".."
import { renderNavList } from "."

import "./Sidebar.css";

export const Sidebar: React.FunctionComponent<void> = () => {

  return (         
    <div className='sidebar'>
      <div className='sidebar-content'>
        <Link to="/" className='logo-container logo-text'>agilify<font className='logo-text-accent'>.</font>it</Link>
        <div className='sidebar-entry-spacer' />
        {renderNavList(NavigationList)}
      </div>
    </div>
  ); 
} 