import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";

import AgileToolkit from "./pages/articles/AgileToolkit";
import AgileStandup from "./pages/articles/AgileStandup";
import { getArticles } from "Backend";
import { ScrollToTop } from 'components';

import 'App.css'
import "themes/desktop.css"
import "themes/mobile.css"

const Resources = React.lazy(() => import('./pages/Resources'))
const ClockPage = React.lazy(() => import('./pages/ClockPage'))
const ArticlePage = React.lazy(() => import('./pages/articles/ArticlePage'))
const Legal  = React.lazy(() => import('./pages/legal/Legal'));
const Privacy  = React.lazy(() => import('./pages/legal/Privacy'));
const AboutMe  = React.lazy(() => import('./pages/AboutMe'));
const NoPage  = React.lazy(() => import('./pages/NoPage'));
const Home  = React.lazy(() => import('./pages/Home'));


export default function App() {
  getArticles().map((a) => console.log(a));

  return (     
    <BrowserRouter>
      <ScrollToTop />
      <Routes>         
        <Route path="/" element={<Layout />}> 
          <Route path="/article/agile-toolkit" element={<AgileToolkit />} />
          <Route path="/article/agile-standup" element={<AgileStandup />} />

          {getArticles().map((a) => (
            <Route path={a.path} element={
              <React.Suspense fallback={<></>}>
                <ArticlePage title={a.title} showDisclaimer={a.showDisclaimer} lastChanged={a.lastChanged} titleImage={a.titleImage ?? undefined} />
              </React.Suspense>
            }/>
          ))}

          <Route index element={<React.Suspense fallback={<></>}><Home /></React.Suspense>} />
          <Route path="/about-me" element={<React.Suspense fallback={<></>}><AboutMe /></React.Suspense>} />
          <Route path="/resources" element={<React.Suspense fallback={<></>}><Resources /></React.Suspense>} />
          <Route path="/legal" element={<React.Suspense fallback={<></>}><Legal /></React.Suspense>} />
          <Route path="/privacy" element={<React.Suspense fallback={<></>}><Privacy /></React.Suspense>} />

          <Route path="*" element={<React.Suspense fallback={<></>}><NoPage /></React.Suspense>} />
        </Route>       
        <Route path="/clock" element={<React.Suspense fallback={<></>}><ClockPage /></React.Suspense>} />
      </Routes>
    </BrowserRouter>   
  ); 
} 